<template>
<div>
<app-toolbar/>
   <v-content class="mb-12 pb-12">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center"> 
                <v-col class="text-center" cols="12" md="8">
                    <h1 style="font-family: Abril Fatface !important;" class="primary--text display-2">
                        Galerie
                    </h1>
                    <v-divider class="my-8"/>
                    <div class="px-md-12 mb-12">
                    <span class="text-center">
                        La <strong>galerie</strong> vous présente des exemples de nos spécialités et des différentes animations
                    que nous vous proposons tout au long de l’année.
                    </span>
                    </div>
                    <div v-if="images_knett.length>0" class="py-6">
                        <app-portfolio title="LA KNETT EN IMAGE" :images="images_knett"/>
                    </div>
                    <div v-if="images_food.length>0" class="py-6">
                        <app-portfolio title="NOS SPÉCIALITÉS" :images="images_food"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
   </v-content>
   <app-footer class="mt-12"/>
</div>
</template>
<script>
import Toolbar from '@/components/comps/Toolbar.vue'
import Footer from '@/components/comps/Footer.vue'
import Portfolio from '@/components/comps/Portfolio.vue'

import config from '@/assets/config.json'
import axios from 'axios'

export default {
    components: {
      'app-toolbar' : Toolbar,
      'app-footer' : Footer,
      'app-portfolio' : Portfolio
    },
    name : 'Galery',
    created() {     
        axios({
            method:'get',
            url: config.api_url+'/galery'
        }).then(response => {
            var images=response.data;
            images.forEach(image => {
                if(image.categorie=="KNETT"){
                    this.images_knett.push(image);
                }else if(image.categorie=="FOOD"){
                    this.images_food.push(image)
                }
            });
        })  
    },
    data() {
        return{
            images_knett: [],
            images_food: [],

        }
    }
}
</script>