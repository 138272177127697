<template>
    <div>
        <span class="text-center headline white--text" style="font-family: Abril Fatface !important;" >
            {{title}}
        </span>
        <v-card flat color="transparent">
            <v-container fluid fill-height>
                <v-row>
                    <v-col v-for="(image, i) in images" :key=i cols="6" md="4">
                        <v-card class="d-flex" @click="opendialog(i)">
                            <v-img aspect-ratio=1 :src="conf.api_url+image.image_url">
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img> 
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
       
        <v-dialog max-width="70%" v-model="dialog">
            <v-card flat @click="dialog=false" :ripple="false" color="transparent">
                    <v-img elevation="24" max-height="450px" contain :src="conf.api_url+dialogImg.image_url">
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
            </v-card>
            <div v-if="dialogImg.description!=null">
                <p class="pa-4 text-justify">{{dialogImg.description}}</p>
            </div>
        </v-dialog>
        
    </div>
</template>

<script>
import config from '@/assets/config.json'

export default {
    name : 'Portfolio',
    props: ['title','images'],
    
    data() {
        return {
            conf: config,
            dialog: false,
            dialogImg:{image_url:""}
        }
    },
    methods:{
        opendialog(index){
            this.dialogImg=this.images[index];
            this.dialog=true
        }
    }
}
</script>